let initialised = false;

export default function () {

    const nuxtApp = useNuxtApp();

    const init = async function () {
        if (initialised) return true;
        if (!import.meta.client) return false;
        const _videojs = await import('video.js');
        await import('video.js/dist/video-js.css');
        if (!!nuxtApp.$videojs) return true; // Prevents redefine of $videojs, due to two async calls running at once
        nuxtApp.provide('videojs', _videojs.default);
        initialised = true;
        return true;
    };

    return {init};
}
